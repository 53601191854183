import React, { Component } from 'react'
import "./calculator.scss"
import {  FormContainer, checkableBoolProps, setInputProps, setCurrencyInputProps } from '../common/forms/Form'
import Data from '../data/data'
import $ from 'jquery'
import Card from '../card/card'
import { BaseValidatonSchema, QuoterValidatonSchema } from '../common/forms/constraints/ValidatonSchemas';
import { connect } from 'react-redux'
class Calculator extends Component {
    constructor(props){
        super(props)
        this.state = {
            open:false,
        }
    }
    componentDidMount(){
        // console.log('calculator',this.props)
    }

    formatCurrency = value => value &&
            !isNaN(value.toString().replace(/,/g, '')) &&
            parseFloat(value.toString().replace(/,/g, ''))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    clientInitialValues = {
        fname: null,
        lname: null,
        email: null,
        telephone: null,
        terms: true
    }
    quoterInitialValues = {
        price: null, 
        initial_fee: null,
        afp_savings: 0,
        salary: null,
        motive: '',
    }
    render() {
        return (
            <Data.Consumer>
                {context =>(
                    <div className={"Calculator "+ context.data.open+" "+this.props.page}>
                        {
                            console.log('all of date',context.data)
                        }
                            <div className="content-close-button">
                                <div className="close-calculator" onClick={()=>{context.closeCalculator("close")}}>
                                    <div className="icon-close"></div>
                                </div>
                            </div>
                            <div className={`content-calculator ${context.data.calculate}`}>
                                {/* <div className="presentation-card">
                                    <Card></Card>
                                </div> */}
                            <div className={`message-calculator calculate-init`}>
                            <FormContainer initialValues={this.clientInitialValues} validationSchema={BaseValidatonSchema} onSubmit={context.createClient}>
                            {form => {const {handleSubmit,errors} = form;
                               
                                return(
                                <form className="form-init" onSubmit={handleSubmit}>
                                    <div className="form-calculator">
                                        <h2 className="title">CALCULA TU CUOTA</h2>
                                        <div className={`edit-data ${context.data.firstStep == "active" ? "active" : ""}`} onClick={(e)=>{context.editBack()}}><div className="icon-back"></div>Volver a editar tus datos</div>
                                        <div className={`content-init-data ${context.data.firstStep == "active" ? "hide" : ""}`}>
                                        <p className="sub-title">Antes de comenzar, ingresa tus datos</p>
                                            <div className="content-input nombre">
                                                <input placeholder="Nombre*" {...setInputProps("fname", "input", form)}></input>
                                                <div className="icon user"></div>
                                            </div>
                                            <div className="content-input apellido">
                                                <input placeholder="Apellidos*" {...setInputProps("lname", "input", form)}></input>
                                                <div className="icon user"></div>
                                            </div>
                                            <div className="content-input">
                                                <input placeholder="Correo*" {...setInputProps("email", "input", form)}></input>
                                                <div className="icon mail"></div>
                                            </div>
                                            <div className="content-input">
                                                <input placeholder="Teléfono*" {...setInputProps("telephone", "input", form)}></input>
                                                <div className="icon phone"></div>
                                            </div>
                                            <div className="radio flex-row">
                                                <input type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                                <label className="button-title flex-row" for="terminos"></label>
                                                <div className="radius" id="link-terminos"></div>

                                                    Estoy de acuerdo con las <a  className="d-block terminos pointer" href="/politicas-de-privacidad" target="_blank"> políticas de privacidad</a>
                                            </div>
                                            <button type="submit" className={`next-step btn-calcular`} disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Empezar"}</button>
                                        </div>
                                    </div>
                                </form>
                                )}}
                                </FormContainer>
                               
                                <div className={`content-ask ${context.data.firstStep}`}>
                                    <div className="content-select mt-05">
                                        <div>
                                            <span className="title-1">{context.data.card.name}</span> -  
                                            <span className="title-1">{context.data.card.dormitorio}</span>
                                        </div>
                                        <span className="title-3">Precio desde</span>
                                        <span className="title-4">S/ {this.formatCurrency(context.data.card.min_price)}</span>
                                        {/* <div className="content-input">
                                            <select className="select" placeholder="Teléfono*">
                                                <option select  >403 desde S/ 234,923</option>
                                            </select>
                                        </div> */}
                                        <span className="nota nota-1">*El precio puede variar de acuerdo al piso donde se encuentra el departemento.</span>
                                    </div>
                                    <FormContainer initialValues={{...this.quoterInitialValues, price: context.data.card.min_price}} validationSchema={QuoterValidatonSchema} onSubmit={context.calculate}>
                                    {form =>{
                                        return(
                                    <form className="ask" onSubmit={form.handleSubmit}>
                                        {this.props?.value?.selected?.units && <div className="content-input">
                                            <select {...setInputProps("floor", "input", form)}>
                                                <option selected>Selecciona un piso</option>
                                                {
                                                    this.props?.value?.selected?.units ? 
                                                    this.props?.value?.selected?.units.map((item)=>{
                                                        return(
                                                            <option>{item.name} - {item?.construction?.total_area} m²</option>
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        </div>}
                                        <span>¿Cuánto es tu presupuesto para la cuota inicial?</span>
                                        <span className="d-block tip">Tip: El monto mínimo requerido es el 10% del valor del departamento.</span>
                                        <div className="content-input">
                                            <input placeholder="S/ 0.00*" {...setCurrencyInputProps("initial_fee", "input", form)}></input>
                                        </div>
                                        <span>¿Cuánto tienes ahorrodo en tu AFP?</span>
                                        <span className="d-block tip">Tip: Si no cuentas con AFP deja este espacio en blanco.</span>

                                        <div className="content-input">
                                        <input placeholder="S/ 0.00" {...setCurrencyInputProps("afp_savings", "input", form)}></input>
                                        </div>
                                        <span>¿Cuánto es tu ingreso mensual?</span>
                                        <span className="d-block tip">Tip: Puedes sumarle a tus ingresos el de tu pareja o la persona con la que vas a comprar el departemento.</span>

                                        <div className="content-input">
                                            <input placeholder="S/ 0.00*" {...setCurrencyInputProps("salary", "input", form)}></input>
                                        </div>
                                        { console.log('form',form.errors)}
                                        {/* <span> Cúal es el motivo de tu compra?</span> */}
                                        <span> Este departamento será:</span>
                                        <div className="content-input">
                                            <select className="select" {...setInputProps("motive", "select", form)}>
                                                <option selected disabled value="">Seleccionar*</option>
                                                <option>Primera vivienda</option>
                                                <option>Segunda vivienda</option>
                                                <option>Inversión</option>
                                            </select>
                                        </div> 

                                        {/* <div className="content-answer">
                                            <input name="primera-vivienda" type="radio" id="si"></input>
                                            <input name="primera-vivienda" type="radio" id="no"></input>
                                            <label for="si" className="answer si">Si</label>
                                            <label for="no" className="answer no">No</label>
                                        </div> */}
                                        <button type="submit" className="btn-calcular" disabled={form.isSubmitting}>{form.isSubmitting ? "Calculando..." : "Calcular"}</button>
                                    </form>
                                    )}}
                                    </FormContainer>
                                </div>
                            </div>
                            <div className="message-calculator success-calculate">
                                <div className="message active">
                                    <h2 className="title">CALCULAR CUOTA</h2>
                                    <span className="m-title-1">¡Listo, hicimos unos cálculos por ti!</span>
                                    <span className="m-title-2">Tu cuota mensual a una tasa referencial</span>
                                    <span className="m-title-3">de 8% con plazo de 20 años seriá de:</span>
                                    <span className="amount">S/ {this.formatCurrency(context.data.monthly_fee)}<span className="nexa-book">*</span></span>
                                    <span className="m-title-3">Considerando una Inicial de:</span>
                                    <span className="amount">S/ {this.formatCurrency(context.data.initial_fee)}</span>
                                   
                                </div>
                                {/* <div className="content-select message-center">
                                    <div className="message-ask text-center">
                                        <p className="mb-0">
                                        Separa ahora y te regalamos el <span className="constrast">5% </span>
                                        <span className="constrast">de tu cuota inicial.</span> Además, llévate un giftcard de
                                        <span className="constrast"> S/ 2,000<span className="nexa-book">**</span></span> 
                                        </p>
                                    </div>
                                </div> */}
                                <span className="nota">*Cuota mensual referencial puede variar, de acuerdo a la evaluación realizada por el banco. **Consultar términos y condiciones de la promoción con el asesor de venta.</span>
                                <div className="content-button">
                                    <div className="btn-calcular recalculate" onClick={(e)=>{context.changeCalculate()}}>Recalcular</div>
                                    <a href={`${context.data.message_wpp}`} target="_blank" className="btn-calcular separe contact-asesor" >Contactar asesor</a>
                                </div>
                            </div>
                            </div>
                        </div>
                    
            )}
            </Data.Consumer>
        )
    }
}
const MapStateProps=(state)=>{
    return(
        {value:state.CalculatorStore}
    )
}
export default connect(MapStateProps,null)(Calculator)