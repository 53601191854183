import {  clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs) => {
    return twMerge(clsx(inputs))
}
export const getDorms = (text) => {
    if(text !== null && text !== undefined && text.length > 0 ){
        const dormsArray = text.match(/\d+/g).map(Number);
        console.log('dorms',dormsArray)
        return dormsArray ?? [];
    }
    return [];
}