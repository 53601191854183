import { useEffect, useState } from "react";
import { Typologies } from "../tipologies"

export const normalizeWord = (word) => {
	const normalizeword = word.toLowerCase();
	return normalizeword.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const SectionTypologies = (props) => {
   
    const [typologies,setTypologies] = useState(props.typologies || []);
   
    const [filterTypologies,setFilterTypologies] = useState(typologies);
    const [dorms,setDorms] = useState('all');
    const [vista,setVista] = useState('all');
    // const [buttons,setButtons] = useState(props.data.tipologias.buttons);
    const [buttons,setButtons] = useState([]);

    const combinedFilters = ({array})=>{ 
        let result = typologies || [];
        array.forEach((item, index) => {
			result = item.fn(result, item.parameter);
		});
        return result;
    }
    const filterDorms = (data,dorm)=>{
        if (dorm === 'all') return data
        let result = data.filter ((item)=>{
            return item.dormitorio.includes(dorm) ;
        })
        return result;
    }
    const filterVista = (data,vista)=>{
        if (vista === 'all') return data
        let result = data.filter ((item)=>{
            return normalizeWord(item.vista) === normalizeWord(vista);
        })
        return result;
    }
    const changeDorms = (param)=>{
        setDorms(param);
        let newData = combinedFilters({
			array: [
				{ fn: filterDorms, parameter: param },
				{ fn: filterVista, parameter: vista },
			],
		});
        console.log('new dorm',newData)
        setFilterTypologies(newData);
    }
    const changeVista = (param)=>{
        setVista(param);
        let newData = combinedFilters({
			array: [
				{ fn: filterDorms, parameter: dorms },
				{ fn: filterVista, parameter: param },
			],
		});
        setFilterTypologies(newData);
    }
    useEffect(()=>{
        setTypologies(props.typologies || []);
        setFilterTypologies(props.typologies);
        try {
            document.querySelector(".contact-asesor").href = props.contact
        } catch (error) {
            console.log(error)
        }
    },[props.typologies])
    if(typologies.length === 0  && buttons.length === 0  ) return null
    return <section id="tipologias" className="section-filter">
    <div className="container">
        <div className="options">
            <h2 className="title">ELIGE TU <span className="bold">CASAIDEAL</span></h2>
            <span className="sub-title filter-title">FILTROS</span>
            <span className="sub-title">Número de dormitorios</span>
            <div className={`content-dorms cant-${buttons.length}`} >
                <div className={`btn-radio ${dorms == 1 ? "active":""} `} onClick={()=>changeDorms(1)}>{'01'}</div>
                <div className={`btn-radio ${dorms == 2 ? "active":""} `} onClick={()=>changeDorms(2)}>{'02'}</div>
                <div className={`btn-radio ${dorms == 3 ? "active":""} `} onClick={()=>changeDorms(3)}>{'03'}</div>
                <div className={`btn-radio ${dorms == 'all' ? "active":""} `} onClick={()=>changeDorms('all')}>{'Todos'}</div>
                
            </div>
            <span className="sub-title">Vista del departamento</span>
            <div className="content-vista">
                <div className={`btn-radio ${vista == 'exterior' ? "active":""}`} onClick={e=>changeVista("exterior")}>Exterior</div>
                <div className={`btn-radio ${vista.includes('interior') ? "active":""}`} onClick={e=>changeVista("interior")}>Interior</div>
                <div className={`btn-radio ${vista == 'all' ? "active":""}`} onClick={e=>changeVista("all")}>Todos</div>
            </div>
        </div>
        <div className="result">
            {
                typologies.length > 0 ?
                <Typologies
                    data={filterTypologies}
                    contact={props.contact}
                    page={props.page}
                    showQuoter={props.showQuoter}
                    openModal={props.openModal}

                ></Typologies> : null
            }
        </div>
    </div>
</section>
}